import React, { useEffect, useRef } from "react";
import { TfiAngleRight } from "react-icons/tfi";
import "./violator-card.scss";
import useDeviceSize from "../../hooks/use-device-size";

function ViolatorCard({ className = "" }) {
    const deviceSizes = useDeviceSize();
    const violatorRef = useRef(null);
    const violatorWrapRef = useRef(null);
    const violatorContentRef = useRef(null);

    const toggleContentVisibility = (e) => {
        e.preventDefault();
        violatorRef.current.classList.toggle("closed");

        if (deviceSizes.mdDown) {
            const isClosed = violatorRef.current.classList.contains("closed");
            const top =
                130 +
                28 +
                (isClosed ? 0 : violatorContentRef.current.clientHeight); //header height + violator button Height + violator content height
            document.querySelector("html").style.scrollPaddingTop = top + "px";
        }
    };

    useEffect(() => {
        const handleScroll = (e) => {
            const header = document.querySelector("header.content-container");
            const isi = document.querySelector("#isi");
            const violatorContent = violatorContentRef?.current;
            const violator = violatorRef?.current;
            const violatorWrap = violatorWrapRef.current;

            if (
                !header ||
                !isi ||
                !violator ||
                !violatorContent ||
                !violatorWrap
            ) {
                return;
            }
            const isiOffsetTop = isi.getBoundingClientRect().top;

            const headerHeight = header.clientHeight;
            const violatorHeight = violator.clientHeight;
            const violatorOffsetTop = violatorWrap.getBoundingClientRect().top;

            if (violatorOffsetTop <= headerHeight) {
                violator.classList.add("fixed");
                violator.style.top = headerHeight + "px";
            } else {
                violator.classList.remove("fixed");
                violator.style.top = "auto";
            }

            if (deviceSizes.mdUp) {
                if (isiOffsetTop <= headerHeight + violatorHeight) {
                    violator.classList.add("fadeout");
                } else {
                    violator.classList.remove("fadeout");
                }
            } else {
                const contentOffsetTop =
                    violatorContent.clientHeight +
                    violator.clientHeight +
                    headerHeight;
                if (contentOffsetTop > isiOffsetTop) {
                    violatorContent.classList.add("fadeout");
                } else {
                    violatorContent.classList.remove("fadeout");
                }
            }
        };

        setTimeout(() => {
            handleScroll();
        }, 100);

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [deviceSizes.mdUp]);

    React.useEffect(() => {
        if (deviceSizes.mdDown) {
            const top = 130 + 28 + violatorContentRef.current.clientHeight; //header height + violator button Height + violator content height
            document.querySelector("html").style.scrollPaddingTop = top + "px";
        }
    }, []);

    return (
        <div
            ref={violatorWrapRef}
            className={`violator-card__wrap ${className}`}
        >
            <div ref={violatorRef} className={`violator-card `}>
                <div className="violator-card__btn">
                    <a
                        href="#"
                        onClick={toggleContentVisibility}
                        className="violator-toggle"
                    >
                        <span className="icon show-desktop">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.927"
                                height="17.329"
                                viewBox="0 0 12.927 17.329"
                            >
                                <g
                                    id="Carat"
                                    transform="translate(11.963 16.165) rotate(-90)"
                                >
                                    <path
                                        id="Line"
                                        d="M.584-.786,7.6-10.214"
                                        fill="none"
                                        stroke="#005db9"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        strokeWidth="2.5"
                                    />
                                    <path
                                        id="Line_Copy"
                                        data-name="Line Copy"
                                        d="M-.584-.786-7.6-10.214"
                                        transform="translate(15)"
                                        fill="none"
                                        stroke="#005db9"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        strokeWidth="2.5"
                                    />
                                </g>
                            </svg>
                        </span>
                        NEW FDA APPROVAL
                        <span className="icon show-mobile">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.971"
                                height="4.829"
                                viewBox="0 0 6.971 4.829"
                            >
                                <g
                                    id="Carat"
                                    transform="translate(0.486 0.414)"
                                >
                                    <path
                                        id="Line"
                                        d="M.214.286,2.786,3.714"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#005db9"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        strokeWidth="1"
                                    />
                                    <path
                                        id="Line_Copy"
                                        data-name="Line Copy"
                                        d="M-.214.286-2.786,3.714"
                                        transform="translate(6 0)"
                                        fill="none"
                                        stroke="#005db9"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        strokeWidth="1"
                                    />
                                </g>
                            </svg>
                        </span>
                    </a>
                </div>
                <div
                    className={`violator-card__content`}
                    ref={violatorContentRef}
                >
                    <div className="block-content">
                        <p>
                            <strong>
                                Now FDA approved to treat excessive daytime
                                sleepiness (EDS) in children 6 years of age and
                                older with narcolepsy.
                            </strong>
                        </p>
                    </div>
                    <div className="btn-wrapper">
                        <a
                            href="/now-approved-in-pediatric-patients"
                            className="btn btn-primary"
                        >
                            Learn more
                            <TfiAngleRight />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViolatorCard;
