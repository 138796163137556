import * as React from "react";
import HcpLayout from "../../components/hcp/layout";
import { Seo } from "../../components/seo";
import "./starting-wakix.scss";
import bannerImg from "../../images/starting-wakix/starting-wakix-hero.webp";
import bannerImgMobile from "../../images/starting-wakix/starting-wakix-hero-mobile.webp";
import bannerBlock from "../../images/starting-wakix/expectation-desktop.webp";
import twoColumnImgMobile from "../../images/starting-wakix/expectation-mobile.webp";
// import kristen from "../../images/icons/thumb-round-kristen.webp";
// import kristenName from "../../images/icons/thumb-name-kristen.svg";
import icon1 from "../../images/icons/icon-first-thing.svg";
import iconCheck from "../../images/icons/icon-do.svg";
import iconCross from "../../images/icons/icon-do-not.svg";
import iconChat from "../../images/icons/icons-talk.svg";
import iconCalendar from "../../images/icons/icons-titration.svg";
import { TfiAngleRight } from "react-icons/tfi";
import SocialIconBlock from "../../components/socialIconBlock";
import { Link } from "gatsby";
import LazyLoad from "react-lazy-load";
import { StaticImage } from "gatsby-plugin-image";
// import starting_stayingPDFImg from "../../images/starting-wakix/starting-and-staying-on-track-pdf.webp";
import InnerBanner from "../../components/InnerBanner";
import pillCompareImg from "../../images/starting-wakix/pill-compare-img.webp";
import settingExpectationsAdultsImg from "../../images/starting-wakix/setting-expectations-adults-thumbnail.webp";
import settingExpectationsPedsImg from "../../images/starting-wakix/setting-expectations-peds-thumbnail.webp";

const StartingWakix = () => {
  // const deviceSizes = useDeviceSize();

  return (
    <HcpLayout pageClass="hcp-main starting-wakix">
      <InnerBanner
        topHeading="How to Take WAKIX"
        bannerClass="starting"
        bannerHeroImg={bannerImg}
        bannerHeroImgMobile={bannerImgMobile}
        bannerImgAlt="Kristen, a real person with narcolepsy taking WAKIX, talking with her healthcare provider"
        heroNameImg={
          <StaticImage
            src="../../images/starting-wakix/starting-wakix-hero-name.webp"
            alt="Kristen - Living with narcolepsy Taking WAKIX since 2020"
            width={212}
            height={104}
            quality={95}
            layout="fixed"
            placeholder="none"
            style={{ transition: "opacity 0.5s" }}
            imgStyle={{ opacity: 1 }}
          />
        }
      />

      <section className="inner-first pd-btm-30">
        <div className="centered-content">
          <div className="block-title">
            <h1 className="font-40">How to Take WAKIX</h1>
          </div>
        </div>
      </section>

      <section className="list-section">
        <div className="centered-content">
          <ul className="icon-list">
            <li>
              <div className="icon smaller">
                <img src={icon1} alt="Alarm clock icon" />
              </div>
              <div className="text">
                <h2>
                  WAKIX tablets should be taken once daily in the morning, as
                  soon as you wake up
                </h2>
              </div>
            </li>
          </ul>
          <div className="list-section__content">
            <p>
              <strong>
                WAKIX only needs to be taken once each day in the morning.
              </strong>{" "}
              Avoid taking WAKIX later in the day.
            </p>
            <ul className="blueDot-list left-indent">
              <li>
                If a dose is missed, the next dose should be taken the following
                morning, as soon as you wake up.
              </li>
              <li>WAKIX tablets should not be crushed or chewed.</li>
            </ul>
          </div>
          <div className="list-section__icon">
            <ul className="icon-check">
              <li>
                <strong className="font-21">TAKE WAKIX</strong>
              </li>
              <li>
                <div className="icon">
                  <img src={iconCheck} alt="Checkmark icon" />
                </div>
                <div className="text">
                  <p>In the morning, as soon as you wake up</p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img src={iconCheck} alt="Checkmark icon" />
                </div>
                <div className="text">
                  <p>Once a day, every day</p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img src={iconCheck} alt="Checkmark icon" />
                </div>
                <div className="text">
                  <p>With or without food</p>
                </div>
              </li>
            </ul>
            <ul className="icon-cross has-border">
              <li>
                <strong className="font-21">
                  DO <u>NOT</u> TAKE WAKIX
                </strong>
              </li>
              <li>
                <div className="icon">
                  <img src={iconCross} alt="Crossed out icon" />
                </div>
                <div className="text">
                  <p>Later in the day</p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img src={iconCross} alt="Crossed out icon" />
                </div>
                <div className="text">
                  <p>More than once a day</p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img src={iconCross} alt="Crossed out icon" />
                </div>
                <div className="text">
                  <p>Only on certain days</p>
                </div>
              </li>
            </ul>
          </div>
          <p className="btm-spacer">
            If you have any questions about when WAKIX should be taken, talk
            with your healthcare provider.
          </p>
          <ul className="icon-list">
            <li>
              <div className="icon smaller">
                <img src={iconChat} alt="Talk bubble icon" />
              </div>
              <div className="text">
                <p>
                  <strong>
                    Always take WAKIX exactly as your healthcare provider has
                    prescribed.
                  </strong>
                </p>
              </div>
            </li>
          </ul>

          <div className="block-tablet-strength">
            <h2>WAKIX comes in two tablet strengths (4.45 mg and 17.8 mg)</h2>
            <p>
              Depending on the WAKIX dose, more than one tablet may have to be
              taken.
            </p>
            <div className="block-tablet-strength__media">
              <img
                src={pillCompareImg}
                alt="Tablet size comparison"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="gray-bg titration-section">
        <div className="centered-content">
          <ul className="icon-list">
            <li>
              <div className="icon smaller">
                <img src={iconCalendar} alt="Calendar icon" />
              </div>
              <div className="text">
                <h2>
                  Your healthcare provider will work with you to find the right
                  dose
                </h2>
              </div>
            </li>
          </ul>
          <div className="list-section__content">
            <p>
              Your healthcare provider will start at a lower dose and increase
              the dose each week to find the dose that is right for you. This
              process is called <strong>titration.</strong>
            </p>
            <ul className="blueDot-list left-indent">
              <li>
                Titration can take time, but it's important for you and your
                healthcare provider to find the dose that's right for you.
              </li>
            </ul>
            <p className="top-spacer">
              Talk to your healthcare provider about how you are feeling after
              starting WAKIX.
            </p>
            <ul className="blueDot-list left-indent">
              <li>
                Tell them about any <strong>improvements</strong> you notice as
                well as <strong>any potential side effects</strong>.
              </li>
            </ul>
          </div>
          <ul className="icon-list icon-list--alt">
            <li className="icon-center-mb">
              <div className="icon">
                <img
                  src={iconChat}
                  alt="Talk bubble icon"
                  width="67"
                  height="67"
                  className="icon-chat"
                />
              </div>
              <div className="text mt-spacer">
                <h3 className="black font-18">
                  Do not stop or change the dose on your own without talking to
                  your healthcare provider first. If you have any questions,
                  talk with your healthcare provider.
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="block-starting-staying">
        <div className="centered-content">
          <div className="block-content">
            <p>
              When starting a new medication, it is important to talk to a
              healthcare provider so they can help you understand what to
              expect. Download a guide for a few things to discuss with a
              healthcare provider when getting started with WAKIX.
            </p>
          </div>

          <div className="block-starting-staying__pdfBlock">
            <div className="block-starting-staying__pdfItem">
              <div className="media">
                <img
                  src={settingExpectationsAdultsImg}
                  alt="Getting started and staying on track with WAKIX flashcard thumbnail"
                  className="image"
                />
              </div>
              <div className="content">
                <h2>Getting started and staying on track with WAKIX</h2>
                <div className="btn-wrap">
                  <a
                    href="/pdf/WAKIX-what-to-expect-when-starting-staying-on-treatment.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary btn-primary--md dwn-btn"
                  >
                    Download guide
                    <svg
                      id="download_arrow"
                      data-name="download arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <path
                            id="Clip_4"
                            data-name="Clip 4"
                            d="M0,0H2V16H0Z"
                            fill="none"
                          />
                        </clipPath>
                      </defs>
                      <path
                        id="Fill_1"
                        data-name="Fill 1"
                        d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                        transform="translate(0 17)"
                        fill="#fff"
                      />
                      <g
                        id="Group_5"
                        data-name="Group 5"
                        transform="translate(9)"
                      >
                        <path
                          id="Clip_4-2"
                          data-name="Clip 4"
                          d="M0,0H2V16H0Z"
                          fill="none"
                        />
                        <g
                          id="Group_5-2"
                          data-name="Group 5"
                          clipPath="url(#clip-path)"
                        >
                          <path
                            id="Fill_3"
                            data-name="Fill 3"
                            d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <path
                        id="Fill_6"
                        data-name="Fill 6"
                        d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                        transform="translate(3 9)"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="block-starting-staying__pdfItem">
              <div className="media">
                <img
                  src={settingExpectationsPedsImg}
                  alt="Helping your child start and stay on track with WAKIX flashcard thumbnail"
                  className="image"
                />
              </div>
              <div className="content">
                <h2>Helping your child start and stay on track with WAKIX</h2>
                <div className="btn-wrap">
                  <a
                    href="/pdf/WAKIX-helping-your-child-start-stay-on-track-with-treatment.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary btn-primary--md dwn-btn"
                  >
                    Download guide
                    <svg
                      id="download_arrow"
                      data-name="download arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <path
                            id="Clip_4"
                            data-name="Clip 4"
                            d="M0,0H2V16H0Z"
                            fill="none"
                          />
                        </clipPath>
                      </defs>
                      <path
                        id="Fill_1"
                        data-name="Fill 1"
                        d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                        transform="translate(0 17)"
                        fill="#fff"
                      />
                      <g
                        id="Group_5"
                        data-name="Group 5"
                        transform="translate(9)"
                      >
                        <path
                          id="Clip_4-2"
                          data-name="Clip 4"
                          d="M0,0H2V16H0Z"
                          fill="none"
                        />
                        <g
                          id="Group_5-2"
                          data-name="Group 5"
                          clipPath="url(#clip-path)"
                        >
                          <path
                            id="Fill_3"
                            data-name="Fill 3"
                            d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <path
                        id="Fill_6"
                        data-name="Fill 6"
                        d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                        transform="translate(3 9)"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-block two-columns no-pb no-pt">
        <div className="col bg-green">
          <div className="inner" style={{ flexDirection: "column" }}>
            <div className="inner__content">
              <StaticImage
                src="../../images/icons/icon-copay-zero.svg"
                placeholder="blurred"
                alt="$0 WAKIX copay program icon"
                className="zero-copay"
                width="128"
                height="114"
              />
              <div className="text">
                <h3 className="h3-spacer asap">
                  Copay program for eligible patients*
                </h3>
                <div className="btn-wrap">
                  <Link
                    to="/wakix-for-you-support-program"
                    className="btn btn-primary btn-primary--md"
                    aria-label="Learn more about the Wakix for You Support Program"
                    title="Learn more about the Wakix for You Support Program"
                  >
                    Learn more
                    <span class="visually-hidden">
                      about the Wakix for You Support Program
                    </span>
                    <TfiAngleRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="block-title">
              <p className="footnotes footnotes-symbols top-spacer no-spacer-mrg">
                *This offer is valid only for patients who have commercial
                (nongovernment-funded) insurance. Additional terms and
                conditions apply.
              </p>
            </div>
          </div>
        </div>
        <div className="col bg-img bg-img--alt">
          <div className="inner bg-cover">
            <LazyLoad offset={300} debounce={false}>
              <img
                src={bannerBlock}
                className="banner-desktop"
                alt="Chris discussing WAKIX and symptoms with his healthcare provider"
              />
            </LazyLoad>
            <img
              src={twoColumnImgMobile}
              className="two-columns-mobile"
              alt="Chris discussing WAKIX and symptoms with his healthcare provider"
            />
            <div className="content">
              <h3 className="txt-shadow-white">
                What to expect
                <br /> with WAKIX
              </h3>
            </div>
            <div className="btn-wrap">
              <Link
                to="/starting-wakix/what-to-expect"
                className="btn btn-primary btn-primary--md"
                rel="noopener noreferrer"
              >
                Find out
                <TfiAngleRight />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <SocialIconBlock />
    </HcpLayout>
  );
};

export default StartingWakix;

export const Head = () => (
  <Seo
    title="How to Take WAKIX® (pitolisant)"
    description="Learn about when to take WAKIX and how it is dosed."
    keywords="WAKIX dosing, pitolisant dosing, how to take WAKIX, how to take pitolisant"
  />
);
