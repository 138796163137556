import React from "react";
import supportDoctorIcon from "../../images/HCP Locator Icon.svg";


function StickySupport() {
  return (
    <div className="sticky-support">
      <span className="sp-icon">
        <img
          src={supportDoctorIcon}
          alt="Sleep specialist for narcolepsy icon"
        />
        {/* <Link
          to="/resources-and-support/find-a-healthcare-provider"
          className="text"
        >
          Find a sleep specialist near you
        </Link> */}
        <a
          href="/resources-and-support/find-a-healthcare-provider"
          className="text"
        >
          Find a healthcare provider near you
        </a>
      </span>
    </div>
  );
}

export default StickySupport;
