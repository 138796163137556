import React from 'react';
import LazyLoad from "react-lazy-load";
import useDeviceSize from "../../hooks/use-device-size";
import StickySupport from '../sticky-support';
import ViolatorCard from '../violator-card';
import './inner-banner.scss'

const InnerBanner = ({
    topHeading,
    bannerClass = '',
    bannerHeroImg,
    bannerHeroImgClass = '',
    bannerImgAlt,
    bannerHeroImgMobile,
    bannerHeroImgMobileClass = '',
    heroNameImg,
    heroNameClass = '',

}) => {
    const deviceSizes = useDeviceSize();

    return (
        <>
            <ViolatorCard
                className='hasViolatorBanner'
            />

            <div className="top-heading">
                <div className="centered-content">
                    <h2 dangerouslySetInnerHTML={{ __html: topHeading }} ></h2>
                </div>
            </div>

            <div className="hero-wrapper row-full hasViolatorBanner">
                <div className="hero-content-wrapper">
                    <div className={`hero-banner inner bg-cover ${bannerClass}`}>
                        {deviceSizes?.smUp ? (
                            <LazyLoad offset={300} debounce={false}>
                                <img
                                    src={bannerHeroImg}
                                    alt={bannerImgAlt}
                                    className={bannerHeroImgClass}
                                />
                            </LazyLoad>
                        ) : (
                            <LazyLoad offset={300} debounce={false}>
                                <img
                                    src={bannerHeroImgMobile}
                                    alt={bannerImgAlt}
                                    className={bannerHeroImgMobileClass}
                                />
                            </LazyLoad>
                        )}
                        <div className="centered-content">
                            <div className={`hero-name ${heroNameClass}`}>
                                {heroNameImg}
                            </div>
                        </div>
                    </div>
                </div>

                <StickySupport />
            </div>
        </>
    )
}

export default InnerBanner;